import React, { FC, useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, makeStyles, Box } from '@mui/material';
import { useHistory, Link } from 'react-router-dom';
import Logo from "../../assets/images/emerson.png";
import { useOktaAuth } from '@okta/okta-react';
import { getEnv, lowerToUpper } from "../../utils/Helper";
import { useTranslation } from 'react-i18next';
import { deleteActiveSession, getLanguageListData } from "../../store/actions/schemaActions";
import { useDispatch, useSelector } from 'react-redux';
import store, { RootState } from '../../store/Store';

import { deleteUserOktaSession, deleteIntUserOktaSession} from "../../api/home";

interface Language {
  lang_desc: string;
  std_code: string;
  trans_code: string;
}

export const Header = ({ ...props }) => {
  let history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null || {});
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [language, setLanguage] = useState('');


  const langaugeList = useSelector((state: RootState) => state.schemaData.languageList);
  const userActiveSession = useSelector((state: RootState) => state.schemaData.activeSession)


  useEffect(() => {
    dispatch(getLanguageListData({}));
    setLanguage(i18n.language.replace("_","-"))
    return () => {
      localStorage.setItem('i18nextLng', '')
    }
  }, [])



  const login = () => oktaAuth.signInWithRedirect();

  const logout = async () => {
    localStorage.setItem('i18nextLng', '')
    // store.dispatch(deleteActiveSession(null))
    deleteUserOktaSession(null)
    deleteIntUserOktaSession(null)
    await oktaAuth.signOut();
  };

  const onHover = () => {
    return {
      style: {
        cursor: 'pointer'
      },
    }
  }

  useEffect(() => {
    if (userActiveSession?.status === 'ACTIVE' && userActiveSession?._links?.user && !authState?.isAuthenticated) {
      setUserInfo({ name: userActiveSession._links.user.name })
    }
  }, [userActiveSession])

  /* istanbul ignore next */
  useEffect(() => {
    /* istanbul ignore else  */
    if (!authState || !authState.isAuthenticated) {
      setUserInfo({ name: 'Guest User' })
    } else {
      oktaAuth.getUser().then((info: any) => {
        setUserInfo(info);
      })
      // *---@ts-ignore
      // let access_token = authState.accessToken?.accessToken;
      // console.log(access_token);
    }
  }, [authState, oktaAuth]);

  const handleLanguageChange = (e: any) => {
    setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value.replace("-","_"));
    window.location.reload();
  }

  const handleRedirect = (event: any, path: any) => {
    event.preventDefault();
    history.push(`${getEnv("PUBLIC_URL")}${path}`);
  }
  // const classes = useStyles();

  return (
    <div className='pa_header_container' style={{ visibility: props.showHeader ? 'visible' : 'hidden', height: '84px' }}>
      <AppBar className={"pa_header_header"}>
        <Toolbar>
          <img src={Logo} alt="logo" className={["siteLogo", "pa_header_logo_title"].join(" ")} onMouseOver={onHover} onClick={(event) => handleRedirect(event, '/')} title="Emerson" />
          < Typography variant="h6" align={'left'} className={"pa_header_title"} >
            {/* <span className="pa_header_title">{t('Product Advisor')}</span> */}
          </Typography>
          <Box component="div" display="inline" pr={2}>
            <select value={language} onChange={handleLanguageChange} style={{ border: "none", fontSize: "small" }}>
              {langaugeList.map(({ std_code,  trans_code, lang_desc }: Language) => <option key={trans_code} value={std_code}>{lang_desc}</option>)}
            </select>
          </Box>
          <Box component="div" display="inline"><div style={{ fontSize: 'small' }}>{
            //@ts-ignore
            userInfo && userInfo.name
          }
          </div>
            {((authState && authState.isAuthenticated) || userActiveSession?.status === 'ACTIVE') && <Button onClick={logout} color="inherit">Logout</Button>}
            {(authState && !authState.isAuthenticated && !userActiveSession) && <Button onClick={login} color="inherit">Login</Button>}
          </Box>
        </Toolbar>
      </AppBar>
    </div >
  );
};
