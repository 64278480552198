import Constant from "../constants/schemaConstants";
import { transform, transformLeftAnchor, transformTopAnchor } from "../../utils/Transform";

const initialState: any = {
  home: [],
  preference: [],
  sizing_input: [],
  loading: false,
  calculation: {},
  sizing_results: {},
  calc_loading: false,
  left_anchor: [],
  right_anchor: [],
  top_anchor: [],
  production_list: [],
  languageList: [],
  defaultNeId: 0,
  activeSession: null
};


export function schemaReducer(state = initialState, action: any) {
  switch (action.type) {
    case Constant.STORE_SCHEMA:
    case Constant.STORE_SIZING_RESULTS:
    case Constant.STORE_LEFT_ANCHOR:
    case Constant.STORE_RIGHT_ANCHOR:
    case Constant.STORE_PRODUCTION_LIST:
    case Constant.STORE_LANGUAGE_LIST:
    case Constant.STORE_ACTIVE_SESSION:
      return {
        ...state,
        loading: true,
      };
    case Constant.STORE_CALCULATION:
      return {
        ...state,
        calc_loading: true,
      };
    case Constant.STORE_SCHEMA_SUCCESS:
      const res: any = transform(action.data);
      const newRes = state.home.filter(function (obj: any) {
        return obj.step_order < res["step_order"];
      });
      const result = [...newRes, res].sort((a, b) =>
        a.step_order > b.step_order ? 1 : -1
      );
      return {
        ...state,
        loading: false,
        home: result,
      };
    case Constant.STORE_CALCULATION_SUCCESS:
      return {
        ...state,
        calc_loading: false,
        calculation: action.data,
      };
    case Constant.STORE_CALCULATION_ERROR:
      return {
        ...state,
        calc_loading: false,
        error: action.error,
      };
    case Constant.STORE_SIZING_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        sizing_results: action.data,
      };
    case Constant.STORE_PRODUCTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        production_list: action.data,
      };
    case Constant.STORE_LANGUAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        languageList: action.data,
      };
    case Constant.STORE_TOP_ANCHOR:
      return {
        ...state,
        loading: false,
        top_anchor: transformTopAnchor(action.top_anchor),
      };
    case Constant.STORE_LEFT_ANCHOR_SUCCESS:
      return {
        ...state,
        loading: false,
        left_anchor: transformLeftAnchor(action.left_anchor),
      };
    case Constant.STORE_UPDATE_LEFT_ANCHOR:
      return {
        ...state,
        left_anchor: [...action.payload],
      };
    case Constant.STORE_UPDATE_LEFT_KEY_ANCHOR:
      let itemIndex = state.left_anchor.findIndex(
        (x: any) => x.ne_id === action.payload.ne_id
      );
      state.left_anchor[itemIndex][action.payload.key] = action.payload.value;

      state.left_anchor.forEach((element: any, i: number) => {
        if (element.ne_id > action.payload.ne_id) {
          element.badges = [];
          element.enabled = false;
        }
      });
      return {
        ...state,
        left_anchor: state.left_anchor,
      };
    case Constant.STORE_RIGHT_ANCHOR_SUCCESS:
      const ra_res: any = [];
      action.data.forEach((obj: any, i: number) => {
        ra_res.push(transform(obj));
      });
      return {
        ...state,
        loading: false,
        right_anchor: [
          ...state.right_anchor,
          {
            id: ra_res[0].parent_ne_id,
            fields: ra_res,
            // group: 'group',
            // hr: true
          },
        ],
      };
    case Constant.STORE_UPDATE_RIGHT_ANCHOR:
      const right_index: number = state.right_anchor.findIndex(
        (x: any) => x.id === action.payload.ne_id
      );

      if (action.payload.value && action.payload.value[0]) {
        state.right_anchor[right_index] = action.payload.value[0]
      }
      state.right_anchor.forEach((element: any, i: number) => {
        if (element.id > action.payload.ne_id) {
          state.right_anchor.splice(i, 1);
        }
      });
      return {
        ...state,
        right_anchor: state.right_anchor,
      };
    case Constant.STORE_UPDATE_RIGHT_KEY_ANCHOR:
      const index: number = state.right_anchor.findIndex(
        (x: any) => x.id === action.payload.ne_id
      );
      let element_index = state.right_anchor[index].fields.findIndex(
        (x: any) => x.name === action.payload.name
      );

      if (action.payload) {
        state.right_anchor[index].fields[element_index] = {
          ...state.right_anchor[index].fields[element_index],
          ...action.payload,
        };
      }
      state.right_anchor.forEach((element: any, i: number) => {
        if (element.id > action.payload.ne_id) {
          state.right_anchor.splice(i, 1);
        }
      });
      return {
        ...state,
        right_anchor: state.right_anchor,
      };
    case Constant.STORE_SCHEMA_ERROR:
    case Constant.STORE_SIZING_RESULTS_ERROR:
    case Constant.STORE_LEFT_ANCHOR_ERROR:
    case Constant.STORE_RIGHT_ANCHOR_ERROR:
    case Constant.STORE_PRODUCTION_LIST_ERROR:
    case Constant.STORE_LANGUAGE_LIST_ERROR:
    case Constant.STORE_ACTIVE_SESSION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case Constant.CLEAR_RIGHT_ANCHOR:
      return {
        ...state,
        right_anchor: action.payload,
      };
    case Constant.UPDATE_CURRENT_NEID:
      return {
        ...state,
        defaultNeId: action.payload
      }
    case Constant.STORE_ACTIVE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        activeSession: action.data
      }
    case Constant.DELETE_ACTIVE_SESSION_SUCCESS:
      return {
        ...state,
        activeSession: null
    }
    default:
      return state;
  }
}
