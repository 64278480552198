import { Iselect, IselectOption, Icalc, IleftAnchor, ItopAnchor } from "./iTransform";
import { getEnv, isJson } from "../utils/Helper";
import { updateRightAnchorKeyData } from '../store/actions/schemaActions';
import i18n from "../i18n/configs";

// import { attr_json } from '@emerson/dynamic-ui-dev';

const ui_type = getEnv("REACT_APP_UI_TYPE");

export const transform = (data: any) => {
  let attribute_code_value: any = null;

  // Object.keys(attr_json).forEach((key)=>{
  //   if(data.html_field_name === key){
  //     data["attribute_code_json"] = attr_json[key]
  //   }
  // })

  if(data["attribute_code_json"] && isJson(data["attribute_code_json"])){
    attribute_code_value = JSON.parse(data["attribute_code_json"]);
  }

  let type: string = "";
  let value: any = "";
  let default_columns = "4";
  let options = []; 
  let dataSourceUrl = "";
  let src = "";
  let labelClass = "";
  let wrapperId = "";
  let wrapperClass = "";
  let inputClass = "";
  let contentClass = "";
  let tableClass = "";
  let showLabel = undefined;
  let disabled = undefined;
  let visibled = "visible";
  let placeholder = "";
  let title = "";
  let sortOptions = [];
  let validations = undefined;
  let required = false;
  let text = "";
  let subText = "";
  let textClass = "";
  let textStyles = {};
  let updateFlag = "";
  let targetField = "";
  let updateKey = "";
  let updateValue = "";
  let multiple = undefined;
  let size = "";
  let btnType = "";
  let precision = 1;
  let initOptions = '';
  let conditions=undefined


  
  if(attribute_code_value && attribute_code_value.column){
    default_columns = attribute_code_value.column;
  }

  //dataSourceUrl
  

  if(attribute_code_value && attribute_code_value.dataSourceUrl){
    dataSourceUrl = attribute_code_value.dataSourceUrl;
  }

  
  if(attribute_code_value && attribute_code_value.src){
    src = attribute_code_value.src;
  }

  

  if(attribute_code_value && attribute_code_value.labelClass){
    labelClass = attribute_code_value.labelClass;
  }

  if(attribute_code_value && attribute_code_value.wrapperId){
    wrapperId = attribute_code_value.wrapperId;
  }

  if(attribute_code_value && attribute_code_value.wrapperClass){
    wrapperClass = attribute_code_value.wrapperClass;
  }

  if(attribute_code_value && attribute_code_value.contentClass){
    contentClass = attribute_code_value.contentClass;
  }

  if(attribute_code_value && attribute_code_value.inputClass){
    inputClass = attribute_code_value.inputClass;
  }

  if(attribute_code_value && attribute_code_value.tableClass){
    tableClass = attribute_code_value.tableClass;
  }

  if(attribute_code_value && (attribute_code_value.showLabel === false ||  attribute_code_value.showLabel === true)){
    showLabel = attribute_code_value.showLabel;
  }

  if(attribute_code_value && attribute_code_value.placeholder){
    placeholder = attribute_code_value.placeholder;
  }

  if(attribute_code_value && attribute_code_value.title){
    title = attribute_code_value.title;
  }

  

  if(attribute_code_value && attribute_code_value.disabled){
    disabled = attribute_code_value.disabled;
  }

  if(attribute_code_value && attribute_code_value.visibled){
    visibled = attribute_code_value.visibled;
  }

  if(attribute_code_value && attribute_code_value.sortOptions){
    sortOptions = attribute_code_value.sortOptions;
  }

  if(attribute_code_value && attribute_code_value.validations){
    validations = attribute_code_value.validations;
  }

  if(attribute_code_value && attribute_code_value.required){
    required = attribute_code_value.required;
  }

  if(attribute_code_value && attribute_code_value.subText){
    subText = attribute_code_value.subText;
  }

  if(attribute_code_value && attribute_code_value.textClass){
    textClass = attribute_code_value.textClass;
  }
  if(attribute_code_value && attribute_code_value.textStyles){
    textStyles = attribute_code_value.textStyles;
  }

  if(attribute_code_value && attribute_code_value.text){
    text = attribute_code_value.text;
  }

  if(attribute_code_value && attribute_code_value.updateFlag){
    updateFlag = attribute_code_value.updateFlag;
  }
  if(attribute_code_value && attribute_code_value.targetField){
    targetField = attribute_code_value.targetField;
  }
  if(attribute_code_value && attribute_code_value.updateKey){
    updateKey = attribute_code_value.updateKey;
  }
  if(attribute_code_value && attribute_code_value.updateValue){
    updateValue = attribute_code_value.updateValue;
  }

  if(attribute_code_value && (attribute_code_value.multiple === false ||  attribute_code_value.multiple === true)){
    multiple = attribute_code_value.multiple;
  }

  if(attribute_code_value && attribute_code_value.size){
    size = attribute_code_value.size;
  }

  if(attribute_code_value && attribute_code_value.btnType){
    btnType = attribute_code_value.btnType;
  }

  if(attribute_code_value && attribute_code_value.precision){
    precision = attribute_code_value.precision;
  }

  if(attribute_code_value && attribute_code_value.conditions){
    conditions = attribute_code_value.conditions;
  }
  
  switch (data["gui_type_code"]) {
    // case "RADIO_BUTTON_GROUP": {
    //   type = "RadioGroup";
    //   break;
    // }
    // case "CHECKBOX_GROUP": {
    //   type = "CheckboxGroup";
    //   break;
    // }
    case "RADIO_INPUT": {
      type = data["gui_type_code"];
      if(attribute_code_value && attribute_code_value.options){
        options = attribute_code_value.options;
        initOptions = attribute_code_value.options;
      }
      if(attribute_code_value && attribute_code_value.value){
        value = attribute_code_value.value;
      }
      break;
    }
    case "SINGLE_SELECT": {
      if(attribute_code_value && attribute_code_value.options){
        options = attribute_code_value.options;
        initOptions = attribute_code_value.options;
      }
      if(attribute_code_value && attribute_code_value.value){
        value = attribute_code_value.value;
      }
      type = data["gui_type_code"];
      break;
    }
    case "BUTTON": {
      type = data["gui_type_code"];
      break;
    }
    case "NUMBER_INPUT": {
      type = data["gui_type_code"];
      break;
    }
    case "CHECKBOX_INPUT": {
      type = data["gui_type_code"];
      if(attribute_code_value && attribute_code_value.options){
        options = attribute_code_value.options;
        initOptions = attribute_code_value.options;
      }
      if(attribute_code_value && attribute_code_value.selectedIds){
        value = attribute_code_value.selectedIds;
      }
      break;
    }
    case "TEXT_INPUT": {
      type = data["gui_type_code"];
      break;
    }
    case "TILE_THUMBNAIL": {
      type = data["gui_type_code"];
      if(attribute_code_value && attribute_code_value.data){
        options = attribute_code_value.data;
        initOptions = attribute_code_value.data;
      }
      if(attribute_code_value && attribute_code_value.defaultIds){
        value = attribute_code_value.defaultIds
      }else {
        value = [];
      }
      break;
    }
    case "IMAGE_CARD": {
      type = data["gui_type_code"];
      break;
    }
    case "CUSTOM_BUTTON_GROUP": {
      type = data["gui_type_code"];
      if(attribute_code_value && attribute_code_value.data){
        options = attribute_code_value.data;
        initOptions = attribute_code_value.data;
      }
      if(attribute_code_value && attribute_code_value.defaultIds){
        value = attribute_code_value.defaultIds
      }else {
        value = [];
      }
      if(attribute_code_value && attribute_code_value.defaultId){
        value = attribute_code_value.defaultId
      }
      break;
    }
    case "IMAGE_BUTTON": {
      type = data["gui_type_code"];
      if(attribute_code_value && attribute_code_value.options){
        options = attribute_code_value.options;
        initOptions = attribute_code_value.options;
      }
      if(attribute_code_value && attribute_code_value.defaultId){
        value = attribute_code_value.defaultId
      }
      break;
    }
    case "DATA_TABLE_SELECT": {
      type = data["gui_type_code"];
      if(attribute_code_value && attribute_code_value.data){
        options = attribute_code_value.data;
        initOptions = attribute_code_value.data;
      }
      if(attribute_code_value && attribute_code_value.selectedIds){
        value = attribute_code_value.selectedIds
      }else {
        value = [];
      }
      if(attribute_code_value && attribute_code_value.selectedId){
        value = attribute_code_value.selectedId
      }
      break;
    }
    case "CUSTOM_TOGGLE_BUTTON": {
      type = data["gui_type_code"];
      if(attribute_code_value && attribute_code_value.data){
        options = attribute_code_value.data;
        initOptions = attribute_code_value.data;
        
      }
      if(attribute_code_value && attribute_code_value.defaultId){
        value = attribute_code_value.defaultId
      }
      break;
    }
    case "DATA_TABLE":
    case "PRODUCTS_LIST":
    case "ITEMS_TABLE":
    case "IMAGE_THUMBNAIL": {
      type = data["gui_type_code"];
      if(attribute_code_value && attribute_code_value.data){
        options = attribute_code_value.data;
        initOptions = attribute_code_value.data;
      }
      if(attribute_code_value && attribute_code_value.defaultIds){
        value = attribute_code_value.defaultIds
      }else {
        value = [];
      }
      break;
    }
    case "SUGGESTION_CARD":{
      type = data["gui_type_code"];
      options = data.apiData ? data.apiData : attribute_code_value.data;
      break;
    }
      
    case "TABLE_INPUT": {
      type = data["gui_type_code"];
      if(attribute_code_value && attribute_code_value.data){
        options = attribute_code_value.data;
        initOptions = attribute_code_value.data;
      }
      if(attribute_code_value && attribute_code_value.value){
        value = attribute_code_value.value;
      }
      break;
    }
    default: {
      type = data["gui_type_code"];
      break;
    }
  }
    
  let element_label = data["element_label"];
  
  if([
      "fpa_rh_IamnotSure",
      "fpa_rh_sizingSummeryImage",
      "fpa_rh_ResultTable",
      "fpa_rh_sizingGraph",
      "fpa_rh_ProcessVariables",
      "fpa_rh_FluidProperties"
    ].includes(data["html_field_name"])){
    element_label = "";
  }

  let align = "left";

  if([
    "fpa_lh_WORKSPACE_Submit"
  ].includes(data["html_field_name"])) {
    align = "right";
  }
  

  var selectField: Iselect = {
    name: data["html_field_name"],
    type: type,
    label: element_label,
    showLabel: showLabel,
    wrapperId: wrapperId,
    wrapperClass: wrapperClass,
    labelClass: labelClass,
    // ( the three option below for one
    inputClass: inputClass,
    // or
    tableClass: tableClass,
    // or
    contentClass: contentClass,
    // )
    placeholder: placeholder,
    title: title,
    sortOptions: sortOptions,
    disabled: disabled,
    visibled: visibled,
    sn_id: data["sn_id"],
    sne_id: data["sne_id"],
    np_id: data["np_id"],
    step_order: data["step_order"],
    column: default_columns,
    options: options,
    anchor: data["left_right_anchor_flag"],
    attribute_code_value: isJson(data["attribute_code_json"])
      ? JSON.parse(data["attribute_code_json"])
      : "",
    value: value,
    parent_ne_id: data["parent_ne_id"],
    dataSourceUrl: dataSourceUrl,
    src: src,
    new_line: ui_type === "old" ? true : false,
    align: align,
    validations: validations,
    required: required,
    text: text,
    subText: subText,
    textClass: textClass,
    textStyles: textStyles,
    updateFlag: updateFlag,
    targetField: targetField,
    updateKey: updateKey,
    updateValue: updateValue,
    initOptions: initOptions,
    multiple : multiple,
    size: size,
    btnType: btnType,
    precision:precision,
    conditions: conditions
  };
  return selectField;
};

export const calcPayload = (obj: any, sn_id: any, sne_id: any) => {
  let res: Icalc = {
    ambient_temp_max: "",
    ambient_temp_min: "",
    ambient_temp_norm: "",
    ambient_temp_units: "F",
    calculation: "liquid",
    coolprops_flag: true,
    density_units: "LB/GAL(US)",
    density_variation_flag: false,
    flow_rate_full: "",
    flow_rate_max: "",
    flow_rate_min: "",
    flow_rate_norm: "",
    flow_rate_units: "USGPH",
    fluid_name: "water",
    language: "US",
    line_pressure_fsd: "",
    line_pressure_max: "",
    line_pressure_min: "",
    line_pressure_norm: "",
    line_pressure_units: "PSIG",
    ref_density: "",
    ref_density_units: "KG/M3",
    ref_pressure: 1.01325348872,
    ref_pressure_units: "BAR-A",
    ref_temperature: 15.555555555555555,
    ref_temperature_units: "C",
    sonic_velocity_units: "M/SEC",
    temperature_fsd: "",
    temperature_max: "",
    temperature_min: "",
    temperature_norm: "",
    temperature_units: "F",
    vapor_pressure_units: "PSIA",
    viscosity_max_custom: "",
    viscosity_min_custom: "",
    viscosity_norm_custom: "",
    viscosity_units: "CP",
  };

  const res_obj = {
    element_data: { sn_id: sn_id, sne_id: sne_id },
    data: { ...res, ...obj },
  };
  return res_obj;
};

export const transformLeftAnchor = (data: any) => {
  let results: any = [];
  data.forEach((obj: any, i: number) => {
    let res: IleftAnchor = {
      sn_id: obj["sn_id"],
      ne_id: obj["ne_id"],
      bu_code: obj["bu_code"],
      np_id: obj["np_id"],
      label: obj["element_label"],
      step_order: obj["step_order"],
      gui_type_code: obj["gui_type_code"],
      attribute_json: obj["attribute_code_json"],
      name: obj["html_field_name"],
      selected: i === 0 ? true : false,
      enabled: i === 0 ? true : false,
      badges: [],
    };
    results.push(res);
  });
  return results;
};

export const transformTopAnchor = (data: any) => {
  let results: any = [];
  data.forEach((obj: any, i: number) => {

    let attribute_code_value :any = {};

    if(obj["attribute_code_json"] && isJson(obj["attribute_code_json"])){
      attribute_code_value = JSON.parse(obj["attribute_code_json"]);
    }

    let res: ItopAnchor = {
      name: obj['html_field_name'],
      type: obj['gui_type_code'],
      order: obj['step_order'],
      value: attribute_code_value?.value,
      data: attribute_code_value?.data,
      labelClass: attribute_code_value?.labelClass,
      labelStyle: attribute_code_value?.labelStyle,
    };
    results.push(res);
  });
  return results;
};

  