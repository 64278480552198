import { connect } from "react-redux";
import { getSchemaData, updateCurrentId, getCalculationData, getSizingResults, getLeftAnchorData, getRightAnchorData, updateLeftAnchorData, updateRightAnchorKeyData, updateLeftAnchorkeyData, clearRightAnchorData, getProductionListData, updateRightAnchorData } from "../../store/actions/schemaActions";
import Home from "./Home";
import HomeOld from "./Home_old";
import { getEnv } from '../../utils/Helper';

const ui_type = getEnv("REACT_APP_UI_TYPE");

const mapStateToProps = (state: any) => state;

const mapDispatchToProps = {
    getLeftAnchorData: (payload: any) => getLeftAnchorData(payload),
    getRightAnchorData: (payload: any) => getRightAnchorData(payload),
    updateLeftAnchorData: (payload: any) => updateLeftAnchorData(payload),
    updateRightAnchorKeyData: (payload: any) => updateRightAnchorKeyData(payload),
    updateRightAnchorData: (payload: any) => updateRightAnchorData(payload),
    updateLeftAnchorkeyData: (payload: any) => updateLeftAnchorkeyData(payload),
    clearRightAnchorData: (payload: any) => clearRightAnchorData(payload),
    getProductionListData: (payload: any) => getProductionListData(payload),
    updateCurrentId: (payload: number) => updateCurrentId(payload),
}

const mapDispatchToPropsOld = {
    getSchemaData: (sn_id: string, sne_id: string, value: string) => getSchemaData(sn_id, sne_id, value),
    getCalculationData: (payload: any) => getCalculationData(payload),
    getSizingResults: (payload: any) => getSizingResults(payload),
}


export default connect(mapStateToProps, mapDispatchToProps)(Home);

