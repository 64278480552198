import { compose } from "@reduxjs/toolkit";
import { takeLatest, put, call, select, takeEvery } from "redux-saga/effects";
import {
  getSchemaAPI,
  getCalculationAPI,
  getSizingResultsAPI,
  getLeftAnchorDataAPI,
  getRightAnchorDataAPI,
  getProductionListAPI,
  getLanguageListAPI,
  getExtOktaSession,
  getIntOktaSession,
  deleteUserOktaSession,
  deleteIntUserOktaSession
} from "../../api/home";
import { getPreferenceSchemaAPI } from "../../api/preference";
import { getSizingInputSchemaAPI } from "../../api/sizinginput";
import Constant from "../constants/schemaConstants";
import i18n from "i18next";


export interface ResponseGenerator {
  data?: any;
}

export function* storeSchema() {
  yield takeLatest(Constant.STORE_SCHEMA, storeSchemaSaga);
}
export function* storeSchemaSaga(action: any) {
  try {
    const { data } = yield call(getSchemaAPI, {
      sn_id: action.sn_id,
      sne_id: action.sne_id,
      value: action.value,
    });
    yield put({ type: Constant.STORE_SCHEMA_SUCCESS, data });
  } catch (error) {
    yield put({ type: Constant.STORE_SCHEMA_ERROR, error });
  }
}

export function* storeCalculation() {
  yield takeLatest(Constant.STORE_CALCULATION, storeCalculationSaga);
}
export function* storeCalculationSaga(action: any) {
  try {
    const { data } = yield call(getCalculationAPI, action.payload);
    yield put({ type: Constant.STORE_CALCULATION_SUCCESS, data });
  } catch (error) {
    yield put({ type: Constant.STORE_CALCULATION_ERROR, error });
  }
}

export function* getLanguageList() {
  yield takeLatest(Constant.STORE_LANGUAGE_LIST, getLanguageListSaga);
}
export function* getLanguageListSaga() {
  try {
    const { data } = yield call(getLanguageListAPI);
    yield put({ type: Constant.STORE_LANGUAGE_LIST_SUCCESS, data });
  } catch (error) {
    yield put({ type: Constant.STORE_LANGUAGE_LIST_ERROR, error });
  }
}

export function* storeSizingResults() {
  yield takeLatest(Constant.STORE_SIZING_RESULTS, storeSizingResultsSaga);
}
export function* storeSizingResultsSaga(action: any) {
  try {
    const { data } = yield call(getSizingResultsAPI, action.payload);
    yield put({ type: Constant.STORE_SIZING_RESULTS_SUCCESS, data });
  } catch (error) {
    yield put({ type: Constant.STORE_SIZING_RESULTS_ERROR, error });
  }
}

export function* storeLeftAnchor() {
  yield takeLatest(Constant.STORE_LEFT_ANCHOR, storeLeftAnchorSaga);
}
export function* storeLeftAnchorSaga(action: any) {
  try {
    const { data } = yield call(getLeftAnchorDataAPI, action.payload);
    const left_anchor = data.filter((item: any) => item.left_right_anchor_flag === 'L');
    const top_anchor = data.filter((item: any) => item.left_right_anchor_flag === 'T');
    yield put({ type: Constant.STORE_TOP_ANCHOR, top_anchor });
    yield put({ type: Constant.STORE_LEFT_ANCHOR_SUCCESS, left_anchor });
    let lft_preload = {
      bu_code: '',
      sn_id: '',
      ne_id: '',
    };
    if (data && data[0]) {
      lft_preload = data[0];
    }
    yield put({ type: Constant.UPDATE_CURRENT_NEID, payload: lft_preload.ne_id });
    yield put({
      type: Constant.STORE_RIGHT_ANCHOR, payload: {
        business_unit_code: lft_preload.bu_code,
        p_session_navigation_id: lft_preload.sn_id,
        p_ne_id: lft_preload.ne_id,
        p_request_json: JSON.stringify({}),
        lang: action.payload.lang
      }
    });
  } catch (error) {
    yield put({ type: Constant.STORE_LEFT_ANCHOR_ERROR, error });
  }
}

export function* storeRightAnchor() {
  yield takeLatest(Constant.STORE_RIGHT_ANCHOR, storeRightAnchorSaga);
}
export function* storeRightAnchorSaga(action: any) {
  try {
    const { data } = yield call(getRightAnchorDataAPI, action.payload);
    yield put({ type: Constant.STORE_RIGHT_ANCHOR_SUCCESS, data });
  } catch (error) {
    yield put({ type: Constant.STORE_RIGHT_ANCHOR_ERROR, error });
  }
}

export function* getProductionList() {
  yield takeLatest(Constant.STORE_PRODUCTION_LIST, getProductionListSaga);
}
export function* getProductionListSaga(action: any) {
  const params = {
    "business_unit": "LTA",
    "industry": "",
    "media_state": "",
    "application": "",
    "measured_variable": "",
    "media_name": "",
    "fluid": "",
    "communication_protocol": "",
    "application_requirements": "",
    "electrical_interface": "",
    "fitting_location": "",
    "vessel_type": "",
    "vessel_dimension": "",
    "vessel_dimension_unit": "m",
    "process_pressure_min": "",
    "process_pressure_min_unit": "bar",
    "process_pressure_max": "",
    "process_pressure_max_unit": "bar",
    "process_temperature_min": "",
    "process_temperature_min_unit": "Celsius",
    "process_temperature_max": "",
    "process_temperature_max_unit": "Celsius",
    "ambient_temperature_min": "",
    "ambient_temperature_min_unit": "Celsius",
    "ambient_temperature_max": "",
    "ambient_temperature_max_unit": "Celsius"
  }

  params.business_unit = action.payload['bu_code'] ? action.payload['bu_code'] : '';
  params.industry = action.payload['LTA_r_Industry'] ? action.payload['LTA_r_Industry'] : '';
  params.media_state = action.payload['hLTA_r_MediaState'] ? action.payload['hLTA_r_MediaState'] : '';
  params.application = action.payload['hLTA_r_Application'] ? action.payload['hLTA_r_Application'] : '';
  params.measured_variable = action.payload['hLTA_r_MeasuredVariable'] ? action.payload['hLTA_r_MeasuredVariable'] : '';
  params.communication_protocol = action.payload['hLTA_r1_sCommunication'] ? action.payload['hLTA_r1_sCommunication'] : '';
  params.application_requirements = action.payload['hLTA_r_chkApplicationRequirements'] ? action.payload['hLTA_r_chkApplicationRequirements'].join(';') : '';
  params.fitting_location = action.payload['hLTA_r2_ctbFittingLocation'] ? action.payload['hLTA_r2_ctbFittingLocation'] : '';
  params.vessel_type = action.payload['hLTA_r2_sVesselType'] ? action.payload['hLTA_r2_sVesselType'] : '';

  params.vessel_dimension = action.payload['hLTA_r2_tbDimension']?.vessel_dimension ? action.payload['hLTA_r2_tbDimension']?.vessel_dimension : '';
  if (params.vessel_dimension && action.payload['hLTA_r2_tbDimension']?.vessel_dimension_unit) {
    params.vessel_dimension_unit = action.payload['hLTA_r2_tbDimension']?.vessel_dimension_unit
  };

  if (!params.vessel_dimension) {
    params.vessel_dimension_unit = ''
  }

  params.process_pressure_min = action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_min ? action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_min : '';
  if (params.process_pressure_min && action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_unit) {
    params.process_pressure_min_unit = action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_unit;
  }
  if (!params.process_pressure_min) {
    params.process_pressure_min_unit = ''
  }

  params.process_pressure_max = action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_max ? action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_max : '';

  if (params.process_pressure_max && action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_unit) {
    params.process_pressure_max_unit = action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_unit;
  }

  if (!params.process_pressure_max) {
    params.process_pressure_max_unit = ''
  }

  params.process_temperature_min = action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_min ? action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_min : '';
  if (params.process_temperature_min && action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_unit) {
    params.process_temperature_min_unit = action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_unit;
  }

  if (!params.process_temperature_min) {
    params.process_temperature_min_unit = ''
  }


  params.process_temperature_max = action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_max ? action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_max : '';
  if (params.process_temperature_max && action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_unit) {
    params.process_temperature_max_unit = action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_unit;
  }

  if (!params.process_temperature_max) {
    params.process_temperature_max_unit = ''
  }



  params.ambient_temperature_min = action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_min ? action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_min : '';
  if (params.ambient_temperature_min && action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_unit) {
    params.ambient_temperature_min_unit = action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_unit
  }

  if (!params.ambient_temperature_min) {
    params.ambient_temperature_min_unit = ''
  }

  params.ambient_temperature_max = action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_max ? action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_max : '';
  if (params.ambient_temperature_max && action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_unit) {
    params.ambient_temperature_max_unit = action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_unit;
  }

  if (!params.ambient_temperature_max) {
    params.ambient_temperature_max_unit = ''
  }

  const params2 = {
    business_unit_code: action.payload.bu_code,
    p_session_navigation_id: action.payload.sn_id,
    p_ne_id: action.payload.ne_id,
    p_request_json: JSON.stringify({}),
    lang: action.payload.lang,
  }

  try {
    if (action.payload.notrenderd) {
      const response1: ResponseGenerator = yield call(getProductionListAPI, params);
      const response: ResponseGenerator = yield call(getRightAnchorDataAPI, params2);
      
      if(response1.data && response1.data.data){
        response1.data.data.forEach((item: any)=>{
          if(item.primaryUrl){
            item.primaryUrl = item.primaryUrl.replace('en-us', i18n.language.replace("_", "-"))
          }
          if(item.secondaryUrl){
            item.secondaryUrl = item.secondaryUrl.replace('en-us', i18n.language.replace("_", "-"))
          }
        })
      }
      response.data[0].apiData = response1.data.data;
      yield put({ type: Constant.STORE_RIGHT_ANCHOR_SUCCESS, data: response.data });
    } else {
      const response1: ResponseGenerator = yield call(getProductionListAPI, params);
      if(response1.data && response1.data.data){
        response1.data.data.forEach((item: any)=>{
          if(item.primaryUrl){
            item.primaryUrl = item.primaryUrl.replace('en-us', i18n.language.replace("_", "-"))
          }
          if(item.secondaryUrl){
            item.secondaryUrl = item.secondaryUrl.replace('en-us', i18n.language.replace("_", "-"))
          }
        })
      }
      yield put({
        type: Constant.STORE_UPDATE_RIGHT_KEY_ANCHOR,
        payload: {
          name: 'hLTA_r3_production',
          options: response1.data.data,
          ne_id: action.payload.ne_id,
        }
      });
      yield put({ type: Constant.STORE_PRODUCTION_LIST_SUCCESS, data: response1.data.data });
    }
  } catch (error) {
    yield put({ type: Constant.STORE_PRODUCTION_LIST_ERROR, error });
  }

}

export function* storeActiveSession() {
  yield takeLatest(Constant.STORE_ACTIVE_SESSION, storeActiveSessionSaga);
}
export function* storeActiveSessionSaga(action: any) {
  try {
    const { data } = yield call(getExtOktaSession, action.payload);
    yield put({ type: Constant.STORE_ACTIVE_SESSION_SUCCESS, data });
  } catch (error) {
    try {
      const { data } = yield call(getIntOktaSession, action.payload);
      yield put({ type: Constant.STORE_ACTIVE_SESSION_SUCCESS, data });
    } catch (error) {
      yield put({ type: Constant.STORE_ACTIVE_SESSION_ERROR, error });
    }
  }
}

export function* deleteActiveSession() {
  yield takeLatest(Constant.DELETE_ACTIVE_SESSION, deleteActiveSessionSaga);
}
export function* deleteActiveSessionSaga(action: any) {
  try {
    const response1: ResponseGenerator = yield call(deleteUserOktaSession, action.payload);
    const response2: ResponseGenerator = yield call(deleteIntUserOktaSession, action.payload);
    yield put({ type: Constant.DELETE_ACTIVE_SESSION_SUCCESS, data: null});
  } catch (error) {
    try {
      const response2: ResponseGenerator = yield call(deleteIntUserOktaSession, action.payload);
      yield put({ type: Constant.DELETE_ACTIVE_SESSION_SUCCESS, data: null });
    } catch (error) {
      yield put({ type: Constant.DELETE_ACTIVE_SESSION_SUCCESS, data: null });
    }
  }
}